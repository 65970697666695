var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "outer-box vertical-center-transform" }, [
    _c("div", { staticClass: "posRel" }, [
      _c("div", { staticClass: "col-xs-12 col-md-6" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 login-box" },
          [
            _vm._m(0),
            !_vm.accountVerified
              ? _c("Auth", {
                  attrs: {
                    busy: _vm.busy,
                    errorMessage: _vm.errorMessage,
                    brandRefer: _vm.brandRefer,
                    accessInvitaion: _vm.accessInvitaion
                  },
                  on: { submit: _vm.authenticateUser }
                })
              : _vm._e(),
            _vm.accountVerified & !_vm.otpVerified
              ? _c("TwoStep", {
                  attrs: {
                    busy: _vm.busy,
                    user: _vm.user,
                    errorMessage: _vm.errorMessage
                  },
                  on: {
                    submit: _vm.loginUser,
                    authenticateUserAgain: _vm.authenticateUser
                  }
                })
              : _vm._e(),
            !_vm.accountVerified
              ? _c(
                  "router-link",
                  {
                    staticStyle: { float: "left" },
                    attrs: { to: "/reset/password" }
                  },
                  [_vm._v("Forgot password?")]
                )
              : _vm._e(),
            _c("br"),
            _c("br"),
            _vm.accountVerified & _vm.otpVerified
              ? _c("div", { staticClass: "two-step" }, [
                  _c("h2", { staticStyle: { "text-align": "center" } }, [
                    _vm._v("Select Brand To Login")
                  ]),
                  _c(
                    "form",
                    {
                      attrs: { "data-vv-scope": "switch-user-brand" },
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.switchUserBrand($event)
                        }
                      }
                    },
                    [
                      _c("div", [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.brandId,
                                expression: "brandId"
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'"
                              }
                            ],
                            class: {
                              input: true,
                              "is-danger": _vm.errors.has(
                                "switch-user-brand.brandId"
                              )
                            },
                            attrs: { name: "brandId" },
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.brandId = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              }
                            }
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v("Select Brand")
                            ]),
                            _vm._l(_vm.userBrands, function(userBrand) {
                              return _c(
                                "option",
                                {
                                  key: userBrand.id,
                                  domProps: { value: userBrand.brand.id }
                                },
                                [_vm._v(_vm._s(userBrand.brand.name))]
                              )
                            })
                          ],
                          2
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "btn-lg-green",
                            attrs: { type: "submit", disabled: _vm.busy }
                          },
                          [_vm._v("LOGIN NOW")]
                        ),
                        _c("br"),
                        _c("br")
                      ])
                    ]
                  )
                ])
              : _vm._e(),
            _c(
              "h4",
              {
                staticClass: "pull-left",
                staticStyle: { "font-size": "25px" }
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "btn-sm-green",
                    staticStyle: {
                      cursor: "pointer",
                      "text-decoration": "none"
                    },
                    on: { click: _vm.pushToRegisterPage }
                  },
                  [_vm._v("BRANDS ONLY: CREATE NEW ACCOUNT")]
                )
              ]
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        {
          staticClass: "col-xs-12 col-md-6 video-out vertical-center-transform"
        },
        [_c("div", { staticClass: "video-box" }, [_c("TutorialVideos")], 1)]
      ),
      _c("div", { staticClass: "clearfix" })
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xs-12 text-center" }, [
      _c("img", {
        staticClass: "pickl-logo",
        attrs: { src: "/img/pickl-logo.png" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }