<template>
    <div class="outer-box vertical-center-transform">
        <div class="posRel">
          <div class="col-xs-12 col-md-6">
              <div class="col-xs-12 login-box">
                  <div class="col-xs-12 text-center">
                      <img class="pickl-logo" src="/img/pickl-logo.png" />
                  </div>
                  <Auth :busy="busy" v-if="!accountVerified"  @submit="authenticateUser" :errorMessage="errorMessage" :brandRefer="brandRefer" :accessInvitaion="accessInvitaion"/>
                  <TwoStep :busy="busy" v-if="accountVerified & !otpVerified" @submit="loginUser" @authenticateUserAgain="authenticateUser" :user="user"  :errorMessage="errorMessage"/>
                  <router-link v-if="!accountVerified" to="/reset/password" style="float:left;">Forgot password?</router-link><br/><br/>
                  <div class="two-step" v-if="accountVerified & otpVerified">
                    <h2 style="text-align:center;">Select Brand To Login</h2>
                    <form v-on:submit.prevent="switchUserBrand" data-vv-scope="switch-user-brand">
                      <div>
                        <select v-model="brandId" name="brandId" v-validate="'required'" :class="{'input': true, 'is-danger': errors.has('switch-user-brand.brandId') }">
                          <option value="">Select Brand</option>
                          <option v-for="userBrand in userBrands" :key="userBrand.id" :value="userBrand.brand.id" >{{userBrand.brand.name}}</option>
                        </select>
                        <button class="btn-lg-green" type="submit" :disabled="busy">LOGIN NOW</button><br/><br/>
                      </div>
                    </form>
                  </div>
                  <h4 class="pull-left" style="font-size:25px;"><a class="btn-sm-green" @click="pushToRegisterPage" style="cursor:pointer;text-decoration:none;">BRANDS ONLY: CREATE NEW ACCOUNT</a></h4>
              </div>
          </div>
          <div class="col-xs-12 col-md-6 video-out vertical-center-transform">
              <div class="video-box">
                  <!-- <h3>Manage Pickl, Picklrs Easily</h3> -->
                  <TutorialVideos></TutorialVideos>
              </div>
          </div>
          <div class="clearfix"></div>
        </div>
    </div>
</template>

<script>
import TutorialVideos from '@/components/common/TutorialVideos.vue'
import Auth from '@/components/common/Auth.vue'
import TwoStep from '@/components/SAdmin/TwoStep.vue'

export default {
  name: 'login',
  components: {
    TutorialVideos,
    Auth,
    TwoStep
  },
  data: function () {
    return {
      busy: false,
      accountVerified: false,
      errorMessage: '',
      user: {},
      otpVerified: false,
      brandId: '',
      userBrands: [],
      verifiedUserId: '',
      brandRefer: false,
      accessInvitaion: {}
    }
  },
  mounted () {
    if (typeof this.$route.query.refer !== 'undefined') {
      this.getInvitationDetails()
    }
  },

  methods: {
    getInvitationDetails () {
      this.$store.dispatch('verifyInvitaionSecret', { secret: this.$route.query.refer })
        .then(
          (response) => {
            if (response.body.data === null) {
              this.$notify({ type: 'error', text: 'Link is invalid or expired!' })
              this.$router.push('/brand/login')
            } else {
              this.brandRefer = true
              this.accessInvitaion = response.body.data
              if (response.body.data.userExists === false) {
                this.$router.push('/brand/signup?refer=' + encodeURIComponent(this.$route.query.refer))
              }
            }
          },
          (response) => {
            this.errorMessage = response.body.message
            this.busy = false
          }
        )
    },

    getUserBrandsList (userId) {
      this.$store.dispatch('getUserBrands', userId)
        .then(
          (response) => {
            this.verifiedUserId = userId
            this.userBrands.push(...response.body.userBrands)
          }
        )
    },

    switchUserBrand () {
      this.$validator.validateAll('switch-user-brand').then((result) => {
        if (result) {
          this.busy = true
          this.$store.dispatch('switchUserBrand', { userId: this.verifiedUserId, brandId: this.brandId, password: this.user.password })
            .then(
              (response) => {
                this.$store.commit('setUserDataToLocalStorage', response.body)
                if (typeof this.$route.query.redirect !== 'undefined' && this.$route.query.redirect !== null) {
                  this.$router.push(this.$route.query.redirect)
                } else {
                  if (response.body.DataAccessSubscriptionId === null && response.body.dashboardDataAccess === 0) {
                    this.$router.push('/brand/payment-history?tab=data-access-subscription')
                  } else {
                    this.$router.push('/brand/dashboard')
                  }
                }
                this.busy = false
              },
              (error) => {
                if (error.body.message !== null) {
                  this.$notify({ type: 'error', text: error.body.message })
                }
              }
            )
        }
      })
    },

    pushToRegisterPage () {
      if (typeof this.$route.query.redirect !== 'undefined' && this.$route.query.redirect !== null) {
        this.$router.push('/brand/signup?redirect=' + encodeURIComponent(this.$route.query.redirect))
      } else {
        this.$router.push('/brand/signup')
      }
    },

    authenticateUser (user) {
      this.busy = true
      this.errorMessage = ''
      this.$http
        .post('verify-account', user, {
          headers: {
            'user-type': 'brand'
          }
        })
        .then(
          function (response) {
            this.accountVerified = true
            this.user = user
            this.$notify({ type: 'success', text: 'Verification code has been send to your registered number!' })
          },
          function (response) {
            this.accountVerified = false
            this.errorMessage = response.body.message
          }
        ).finally(() => {
          this.busy = false
        })
    },
    loginUser (code) {
      this.errorMessage = ''
      this.user.code = code
      if (this.brandRefer === true) {
        this.user.invitationId = this.accessInvitaion.id
      }
      this.$http
        .post('brand-login', this.user, {
          headers: {
            'user-type': 'brand'
          }
        })
        .then(
          function (response) {
            this.otpVerified = true
            this.$store.commit('setTokenToLocalStorage', response.body)
            this.getUserBrandsList(response.body.userId)
          },
          function (response) {
            this.errorMessage = response.body.message
            this.busy = false
          }
        )
    },
    getUserByToken () {
      this.$store.dispatch('getUserData', 'brand')
        .then(
          (response) => {
            this.$store.commit('setUserDataToLocalStorage', response.body)
            if (typeof this.$route.query.redirect !== 'undefined' && this.$route.query.redirect !== null) {
              this.$router.push(this.$route.query.redirect)
            } else {
              if (response.body.subscriptionId == null) {
                this.$router.push('/brand/subscription')
              } else {
                this.$router.push('/brand/dashboard')
              }
            }
            this.busy = false
          },
          (response) => {
            this.errorMessage = response.body.message
            this.busy = false
          }
        )
    }
  }
}
</script>

<style scoped>
.outer-box {
  margin: 0 auto;
  max-width: 1366px;
  width: 100%;
  background-image: url("/img/background-portrait.png");
  background-size: 50% 100%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.pickl-logo {
  margin-bottom: 30px;
  width: 120px;
  height: 120px;
  border-radius: 7px;
}

.login-box {
  margin: 5% auto 0;
  padding: 10px 90px 20px;
}

.video-out {
  padding-left: 0;
    left: auto;
    right: 0;
}

.video-box {
  margin: 0 auto;
  /* margin: 0 0 20px; */
  padding: 25px 0 15px;
}

.video-box h3 {
  font-weight: 700;
  text-align: center;
  font-size: 44px;
  color: black;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1199px) {
  .login-box {
    padding: 10px 35px 20px;
  }
}

@media only screen and (max-width: 991px) {
  .outer-box {
    background-size: 100% 100vh;
        background-repeat: repeat-y;
        position: static;
        transform: none;
  }

  .video-out {
    padding-left: 15px;
        min-height: auto;
        position: static;
        transform: none;
    }

    .video-box {
        margin-bottom: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .login-box {
    padding: 15px;
  }

  .video-box h3 {
    font-size: 36px;
        padding: 15px;
    }
}

@media only screen and (max-height: 660px) {
    .outer-box {
        position: static;
        transform: none;
  }
}
select,
select:hover {
  border: 1px solid #6f3053;
  border-radius: 3px;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 20px;
  color: black;
  width: 100%;
  padding: 14px 42px 14px 16px;
  height: 50px;
  background-position: right 11px center;
  background-size: 20px 12px;
  background-image: url("/img/Create Pickl/selectproductDropdown_icon.png");
  background-color: white;
}
</style>
